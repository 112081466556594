(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"));
	else if(typeof define === 'function' && define.amd)
		define("react-keyboard-event-handler", ["react", "prop-types"], factory);
	else if(typeof exports === 'object')
		exports["react-keyboard-event-handler"] = factory(require("react"), require("prop-types"));
	else
		root["react-keyboard-event-handler"] = factory(root["react"], root["prop-types"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 